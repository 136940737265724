$ = global.$;
;(function($) {
	var defaults = {
		jsAnim: false,
		onInit: function() {}
	};
	var settings = {};
	var selectors = {
		_activeClass:    'active',
		_stenikSelect:   'stenikSelect',
		_selectedHolder: 'selectedHolder',
		_dropdown:       'dropdown',
		_option:         'option',
		_hiddenWrapper:  'stenikHiddenSelect'
	};

	// PRIVATE FUNCTIONS
	function showDropdown($select, $dropdown, jsAnim) {
		$select.addClass(selectors._activeClass);
		if(jsAnim == true) {
			// + jQuery animations
			$dropdown.slideDown(300);
		}
	}
	function hideDropdown($select, $dropdown, jsAnim) {
		$select.removeClass(selectors._activeClass);
		if(jsAnim == true) {
			// + jQuery animations
			$dropdown.slideUp(300);
		}
	}

	// METHODS
	var methods = {
		init: function(options) {
			var selectsCounter = 0;
			return this.each(function() {
				selectsCounter++;
				methods.destroy.call(this, options); // on reinit fix

				var $origSelect   = $(this);
				settings = $origSelect.data('stenikSelect');
				if(typeof(settings) == 'undefined') {
					settings = $.extend({}, defaults, options);
					$origSelect.data('stenikSelect', settings);
				} else {
					settings = $.extend({}, settings, options);
				}

				var $options        = $origSelect.find('option');
				var $parent         = $origSelect.parent();
				var optionsList     = '';
				var $selectedOption = '';

				$options.each(function(i, option) {
					var $option = jQuery(option);
					var isSelected = '';
					var isDisabled = '';
					var optionData = 'option_' + selectsCounter + '_' + i;
					if($option.is(':selected')) {
						$selectedOption = $option;
						isSelected = 'selected';
					}
					if($option.is(':disabled')) {
						isDisabled = 'disabled';
					}
					$option.attr('data-option', optionData);
					optionsList += '<span class="' + selectors._option + ' ' + isSelected + ' ' + isDisabled + '"'+
								   ' data-option="' + optionData + '">' + $option.text() + '</span>';
				});
				var $stenikSelect    = jQuery('<div class="' + selectors._stenikSelect + ' select_' + selectsCounter + '"></div>');
				var $optionsDropdown = jQuery('<div class="' + selectors._dropdown + '">' + optionsList + '</div>');
				var $selectedHolder  = jQuery('<div class="' + selectors._selectedHolder + '">'+ $selectedOption.text() +'</div>');

				$stenikSelect.append($selectedHolder).append($optionsDropdown)
							 .insertBefore($origSelect);
				if($origSelect.prop('disabled') == true) {
					$stenikSelect.addClass('disabled');
				}

				// Dropdown open/hide
				$selectedHolder.on('click', function(e) {
					if($stenikSelect.hasClass(selectors._activeClass)) {
						hideDropdown($stenikSelect, $optionsDropdown, settings.jsAnim);
					} else {
						showDropdown($stenikSelect, $optionsDropdown, settings.jsAnim);
					}
				});
				// Hide the dropdown on outside click
				$(document).on('click', function(e) {
					if(!$stenikSelect.is(e.target) && $stenikSelect.find(e.target).length == 0) {
						hideDropdown($stenikSelect, $optionsDropdown, settings.jsAnim);
					}
				});

				// Option click
				$optionsDropdown.on('click', '.option:not(.disabled)', function(e) {
					e.preventDefault();
					var $clickedOption = $(this);
					$options.removeAttr('selected'); // reset
					$optionsDropdown.find('.' + selectors._option + '.selected').removeClass('selected');
					$clickedOption.addClass('selected');

					$selectedHolder.text($clickedOption.text());
					var optionData = $clickedOption.data('option');
					var $selectedOption = $origSelect.find('option').filter('[data-option="'+optionData+'"]');
					$selectedOption.attr('selected', 'selected').trigger('change');
					$origSelect.val($selectedOption.val()).find('select').trigger('change')
					$stenikSelect.trigger("change");
					document.getElementById($origSelect.attr('id')).dispatchEvent(new Event("change"));

					$stenikSelect.removeClass('active'); // close the dropdown
				});
				$origSelect.wrap('<div class="' + selectors._hiddenWrapper + '" style="display: none;"></div>');
			
				settings.onInit.call(this);
			});
		},
		destroy: function() {
			return $(this).each(function() {
				var $this = $(this);
				$this.removeData('stenikSelect');

				// @TODO: more efficient delete method...
				var $wrapper = $this.parents('.' + selectors._hiddenWrapper).first();
				if($wrapper.length) {
					var $stenikSelect = $wrapper.parent().find('.' + selectors._stenikSelect).first();
					$this.insertBefore($wrapper);
					$wrapper.remove();
					if($stenikSelect.length) {
						$stenikSelect.remove();
					}
				}
			});
		}
	};

	$.fn.stenikSelect = function(methodOrOptions) {
		if ( methods[methodOrOptions] ) {
			return methods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
		} else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
			// Default to "init"
			return methods.init.apply( this, arguments );
		} else {
			$.error( 'Method ' +  methodOrOptions + ' does not exist on jQuery.stenikSelect' );
		}
	};
})(jQuery);
