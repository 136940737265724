/*
 * SUPER SIMPLE FILTER PLUGIN
 * VERSION: 1.0
 * DATE: 07-04-2015
 * @license Copyright(c) StenikGroup LTD. All rights reserved.
 * @author: gani@stenik.bg
 * -------------------------------------------------------------------
 * OPTIONS:
 * -- defaultFilter => 'filter-name'(string; set the default active filter)
 * -------------------------------------------------------------------
 * METHODS:
 * -- init    => plugin init
 * -- destroy => plugin inst clear
 * -- set(filterName)     => set 'filterName' items active
 * -------------------------------------------------------------------
 * CALLBACKS:
 * -- onInit   => triggered after init
 * -- onChange => triggered after filter change
 * 				  returned data: event = { filter: filterName, activeItems: $filteredItems }
 */
;(function($) {
	var defaults = {
		defaultFilter: 'all',
		onInit: function() {},
		onChange: function(event) {}
	};

	var settings = {};

	// METHODS
	var methods = {
		init: function(options) {
			return this.each(function() {
				methods.destroy.call(this, options); // on reinit fix

				var $filterWrapper = $(this);
				settings = $filterWrapper.data('stenikFilter');
				
				if(typeof(settings) == 'undefined') {
					settings = $.extend({}, defaults, options);
					$filterWrapper.data('stenikFilter', settings);
				} else {
					settings = $.extend({}, settings, options);
				}

				var $filterHeader = $filterWrapper.find('.filterHeader'),
					$filters      = $filterHeader.find('.filter');

				var $filterContent = $filterWrapper.find('.filterContent'),
					$filterItems   = $filterContent.find('.filterItem');
				
				$filters.on('click', function(e) {
					e.preventDefault();
					var filterName = jQuery(this).data('filter-anchor') || jQuery(this).attr('href');
					if(filterName.length) {
						methods.set.call($filterWrapper, filterName.replace('#', ''));
					}
				});
				var hash = window.location.hash.replace('#', '');
				console.log(hash)
				if(hash) {
					// set the filter, if has any item with that hash,
					var $filteredItems = $filterItems.filter(function() {
						return jQuery(this).data('filter').match('\\b'+hash+'\\b');
					});
					if($filteredItems.length)
						methods.set.call($filterWrapper, hash);
					else
						methods.set.call($filterWrapper, settings.defaultFilter);
				} else {
					methods.set.call($filterWrapper, settings.defaultFilter);
				}

				$(document).on('click', 'a', function(e) {
					if(/#/.test(this.href)) {
						var hash = this.href.replace(/^.*?(#|$)/,'');
						var $filteredItems = $filterItems.filter(function() {
							return jQuery(this).data('filter').match('\\b'+hash+'\\b');
						});
						if($filteredItems.length) {
							// has filter with the hash
							e.preventDefault();
							methods.set.call($filterWrapper, hash);
						}
					}
				});

				settings.onInit.call(this);
			});
		},
		destroy: function() {
			return jQuery(this).each(function() {
				var $filterWrapper = jQuery(this);
				$filterWrapper.removeData('stenikFilter');
			});
		},
		set: function(filterName) {
			return jQuery(this).each(function() {
				var $filterWrapper = jQuery(this);

				var $filters = $filterWrapper.find('.filterHeader .filter');

				var $filterContent = $filterWrapper.find('.filterContent'),
					$filterItems   = $filterContent.find('.filterItem');

				var $filteredItems, event;
				if(filterName) {
					$filteredItems = $filterItems.filter(function() {
						return jQuery(this).data('filter').match('\\b'+filterName+'\\b');
					});

					$filters.filter('.selected').removeClass('selected');
					$filters.filter(function() {
						return (jQuery(this).data('filter-anchor') === filterName) || (jQuery(this).attr('href') === '#' + filterName);
					}).addClass('selected');


					$filterItems.filter('.filterSelected').addClass('animOut').delay(250).queue(function() {
						jQuery(this).removeClass('animOut').removeClass('filterSelected').dequeue();
						$filteredItems.addClass('filterSelected');
					});
					if(!$filterItems.filter('.filterSelected').length)
						$filteredItems.addClass('filterSelected');

					event = {
						filter: filterName,
						activeItems: $filteredItems,
					}
					settings.onChange.call(this, event);
				}
			});
		}
	};
	$.fn.stenikFilter = function() {
		var method = arguments[0];
		
		if(methods[method]) {
			method = methods[method];
			arguments = Array.prototype.slice.call(arguments, 1);
		} else if( typeof(method) == 'object' || !method ) {
			method = methods.init;
		} else {
			$.error( 'Method ' +  method + ' does not exist on jQuery.stenikFilter' );
			return this;
		}
		
		return method.apply(this, arguments);
	}
})(jQuery);
